import { default as index0wZrjTLn2DMeta } from "/opt/buildhome/repo/pages/[username]/index.vue?macro=true";
import { default as _91featureid_93cnQCw5YsLPMeta } from "/opt/buildhome/repo/pages/channel/[channelid]/[nav]/[featureid].vue?macro=true";
import { default as indexLYU7AzCWL4Meta } from "/opt/buildhome/repo/pages/channel/[channelid]/[nav]/index.vue?macro=true";
import { default as indexBrann7swSKMeta } from "/opt/buildhome/repo/pages/channel/[channelid]/index.vue?macro=true";
import { default as indexGSt9fB8NnXMeta } from "/opt/buildhome/repo/pages/channel/index.vue?macro=true";
import { default as _91chatid_933x01XJlHQOMeta } from "/opt/buildhome/repo/pages/chat/[chatid].vue?macro=true";
import { default as chatcdcDIyUuf8Meta } from "/opt/buildhome/repo/pages/chat.vue?macro=true";
import { default as explorerYI361piByMeta } from "/opt/buildhome/repo/pages/explore.vue?macro=true";
import { default as index_copyhvKeYbnBJjMeta } from "/opt/buildhome/repo/pages/index_copy.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as mapsGFhSPoh41JMeta } from "/opt/buildhome/repo/pages/maps.vue?macro=true";
import { default as notificationqzXbaWb8jkMeta } from "/opt/buildhome/repo/pages/notification.vue?macro=true";
import { default as privacy_45policypnUDKF1OY5Meta } from "/opt/buildhome/repo/pages/privacy-policy.vue?macro=true";
import { default as scheduleVfpgDdTWXkMeta } from "/opt/buildhome/repo/pages/schedule.vue?macro=true";
import { default as settingy5EQf90uLhMeta } from "/opt/buildhome/repo/pages/setting.vue?macro=true";
import { default as terms_45and_45conditionsPFDsB8eCTMMeta } from "/opt/buildhome/repo/pages/terms-and-conditions.vue?macro=true";
import { default as _91id_932nt7D8QWCYMeta } from "/opt/buildhome/repo/pages/timeline/[id].vue?macro=true";
import { default as indexYrCOLbJkHTMeta } from "/opt/buildhome/repo/pages/timeline/index.vue?macro=true";
export default [
  {
    name: "username",
    path: "/:username()",
    component: () => import("/opt/buildhome/repo/pages/[username]/index.vue")
  },
  {
    name: "channel-channelid-nav-featureid",
    path: "/channel/:channelid()/:nav()/:featureid()",
    component: () => import("/opt/buildhome/repo/pages/channel/[channelid]/[nav]/[featureid].vue")
  },
  {
    name: "channel-channelid-nav",
    path: "/channel/:channelid()/:nav()",
    component: () => import("/opt/buildhome/repo/pages/channel/[channelid]/[nav]/index.vue")
  },
  {
    name: "channel-channelid",
    path: "/channel/:channelid()",
    component: () => import("/opt/buildhome/repo/pages/channel/[channelid]/index.vue")
  },
  {
    name: "channel",
    path: "/channel",
    component: () => import("/opt/buildhome/repo/pages/channel/index.vue")
  },
  {
    name: "chat",
    path: "/chat",
    component: () => import("/opt/buildhome/repo/pages/chat.vue"),
    children: [
  {
    name: "chat-chatid",
    path: ":chatid()",
    component: () => import("/opt/buildhome/repo/pages/chat/[chatid].vue")
  }
]
  },
  {
    name: "explore",
    path: "/explore",
    component: () => import("/opt/buildhome/repo/pages/explore.vue")
  },
  {
    name: "index_copy",
    path: "/index_copy",
    component: () => import("/opt/buildhome/repo/pages/index_copy.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "maps",
    path: "/maps",
    component: () => import("/opt/buildhome/repo/pages/maps.vue")
  },
  {
    name: "notification",
    path: "/notification",
    component: () => import("/opt/buildhome/repo/pages/notification.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/opt/buildhome/repo/pages/privacy-policy.vue")
  },
  {
    name: "schedule",
    path: "/schedule",
    component: () => import("/opt/buildhome/repo/pages/schedule.vue")
  },
  {
    name: "setting",
    path: "/setting",
    component: () => import("/opt/buildhome/repo/pages/setting.vue")
  },
  {
    name: "terms-and-conditions",
    path: "/terms-and-conditions",
    component: () => import("/opt/buildhome/repo/pages/terms-and-conditions.vue")
  },
  {
    name: "timeline-id",
    path: "/timeline/:id()",
    component: () => import("/opt/buildhome/repo/pages/timeline/[id].vue")
  },
  {
    name: "timeline",
    path: "/timeline",
    component: () => import("/opt/buildhome/repo/pages/timeline/index.vue")
  }
]